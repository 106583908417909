<template>
  <div id="payments">
    <section class="site-section">
      <div class="container-fluid">

        <div class="section-body">
          <div class="row">
            <div class="col-md-4">
              <PaymentForm />
            </div>

            <div class="col-md-5">
              <PaymentInfo />
            </div>

            <div class="col-md-3">
              <div class="small-cart">
                <div class="small-cart-header">
                  <h4 class="small-cart-title" v-text="'אז לסיכום...'" />
                </div>

                <div v-if="cart.products" class="small-cart-body custom-scroll-dark">
                  <CardProductOrder
                    v-for="(product, index) of cart.products"
                    :key="index"
                    :product="product"
                    :changeQuantity="false"
                    :canDelete="false"
                  />
                </div>

                <div class="small-cart-footer">
                  <div class="cart-checkout intermediate-summary">
                    <p><span class="shekel">₪</span> {{allPrice}}</p>
                    <p>סיכום ביניים ({{allQuantity}} מוצרים)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import cartMixin from '@/mixins/cart.mixin'
import CardProductOrder from '@/components/CardProductOrder'
import PaymentForm from '@/components/payments/PaymentForm'
import PaymentInfo from '@/components/payments/PaymentInfo'

export default {
  name: 'Payments',

  components: {
    CardProductOrder, PaymentForm, PaymentInfo
  },

  mixins: [cartMixin],

  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    },

    cart() {
      return this.$store.state.cart.cart
    }
  },

  async created () {
    await this.$store.dispatch('getUserData')
    await this.$store.dispatch('getDetails')
  },

  destroyed() {
    this.$store.commit('setSaveCheckoutStatus', {
      status: false,
      orderId: null,
      payplusIframe: ''
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#payments .container-fluid{
  width: inherit;
  margin-left: 11.25vw;
  margin-right: 4rem;
}

#payments .row .col-md-5{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
#payments .row .col-md-4{
  padding-right: 0;
  border-right: 1px solid #E1E1E1;
}
#payments .row .col-md-3{
  padding-left: 0;
}
.small-cart{
  border: none;
}
.small-cart-header{
  background-color: transparent;
  justify-content: flex-end;
  padding: 1.3rem 1.2rem 1.2rem;
}
.small-cart-title{
  color: #000;
  font-size: 1.6rem;
  direction: rtl;
}
.small-cart-body{
  height: 32.3rem;
  overflow-y: scroll;
  direction: rtl;
}
.small-cart-footer{
  background-color: #fafafa;
  padding: 2rem 1.3rem .4rem 2.1rem;
}
.cart-good{
  padding: 1.4rem 1.4rem .5rem;
}
.site-section{
  padding-top: 2rem;
}

@media (min-width: 1600px) {
  #payments .container-fluid{
    margin-right: 5.8vw;
  }
  #payments .row .col-md-5{
    padding-left: 3.9vw;
    padding-right: 3.9vw;
  }
  #payments .row .col-md-4{
    padding-right: 0;
    border-right: 1px solid #E1E1E1;
  }
  #payments .row .col-md-3{
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  #payments .container-fluid{
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  #payments .row .col-md-4{
    padding-right: 0;
    padding-left: 0;
  }
  #payments .row .col-md-5{
    padding-left: 15px;
    padding-right: 15px;
  }
  #payments .row .col-md-3{
    padding-left: 15px;
  }
}
@media screen and (max-width: 1264px) {
  #payments .container-fluid{
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
</style>
