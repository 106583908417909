<template>
  <div class="payment-container payment-container-1">
    <h4 class="checkout-title">
      תשלום מאובטח
    </h4>

    <Loading v-if="loading" />

    <iframe
      v-if="!paypal"
      v-show="!loading"
      @load="loading = false"
      class="payplus"
      :src="payplusIframe"
      frameborder="0"
      allowfullscreen=""
    ></iframe>

    <div v-if="paypal" ref="paypal" class="paypal-container"></div>
  </div>
</template>

<script>
import api from '../../api/index'

export default {
  name: 'PaymentForm',

  data() {
    return {
      loading: true,
      loaded: false
    };
  },

  mounted() {
    if (this.paypal) {
      const script = document.createElement('script');
      //paypal test sendbox
      //client-id=AZQ7FFzYVOHJmP27BjfN1wWuMYbWrRPcvYayH6RFtN-HelLj5984Olepi1PAsbL6_NqYNjCi249_vEDw

      //production
      //ARVi370OanT6OUnEjmEVvyOeMdxGxlJQ_E9uQ_VzTQKBOvtaV1HkLdg-Reor0P8AzfGKwapKzaOLwjye
      script.src =
        'https://www.paypal.com/sdk/js?client-id=ARVi370OanT6OUnEjmEVvyOeMdxGxlJQ_E9uQ_VzTQKBOvtaV1HkLdg-Reor0P8AzfGKwapKzaOLwjye&currency=ILS'
      script.addEventListener('load', this.setLoaded);
      document.body.appendChild(script);
    }
  },

  methods: {
    async setLoaded() {
      this.loading = false;
      this.loaded = true;
      window.order = this.orderId;
      //window.goThanks = () => this.$router.push({name: 'ThankYou'})
      paypal
        .Buttons({
          async createOrder() {
            const form = new FormData();
            form.append('orderId', window.order);
            const resp = await api.paypal.create(form);
            if (resp) {
              return resp.orderID;
            }
          },
          async onApprove(data) {
            const form = new FormData();
            form.append('paypalData', data);
            form.append('paypalOrderID', data.orderID);
            form.append('orderID', window.order);
            const resp = await api.paypal.capture(form);

            if (resp.result.status === 'COMPLETED') {
              window.location = '/thankyou?orderId='+window.order
            }
          }
        })
        .render(this.$refs.paypal)
    }
  },

  computed: {
    details() {
      return this.$store.getters.details
    },
    cart() {
      return this.$store.state.cart.cart
    },
    orderId() {
      return this.$store.getters.orderId
    },
    payplusIframe() {
      return this.$store.getters.payplusIframe
    },
    paypal() {
      return this.$store.getters.paypal
    }
  },

  created() {
    if (!this.orderId || this.payplusIframe === '')
      this.$router.push({ name: 'checkout' })
  }
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.checkout-title {
  text-align: right;
  margin-bottom: 0;
  margin-right: 22px;
}

.payplus {
  width: 100%;
  height: 850px;
}

.paypal-container {
  padding-top: 1rem;
}

@include media-breakpoint-down(sm) {
  .checkout-title {
    margin-bottom: 1rem;
  }
  .payplus {
    height: 755px;
  }
}
</style>
