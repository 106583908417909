<template>
  <div id="paymentInfo">
    <div class="payment-container payment-container-2">
      <div class="payment-info">
        <div v-if="cart.delivery" class="info-item">
          <span v-text="'משלוח:'" />
          <span v-text="cart.delivery.title" />
        </div>
        <div class="info-item">
          <span v-text="'אמצעי תשלום:'" />
          <span v-text="'כרטיס אשראי'" />
        </div>
        <div class="info-item">
          <span v-text="'שם פרטי:'" />
          <span v-text="user.fname || form && form.get('fname')" />
        </div>
        <div class="info-item">
          <span v-text="'שם משפחה:'" />
          <span v-text="user.lname || form && form.get('lname')" />
        </div>
        <div class="info-item">
          <span v-text="'כתובת דואר אלקטרוני:'" />
          <span v-text="user.mail || form && form.get('email')" />
        </div>
        <div class="info-item">
          <span v-text="'טלפון:'" />
          <span v-text="user.phone || form && (form.get('areaPhone') + form.get('phone'))" />
        </div>
        <div class="info-item">
          <span v-text="'עיר:'" />
          <span v-text="user.address || form && form.get('city')" />
        </div>
        <div class="info-item">
          <span v-text="'רחוב:'" />
          <span v-text="user.street || form && form.get('street')" />
        </div>
        <div class="info-item">
          <span v-text="'מספר:'" />
          <span v-text="user.houseNum || form && form.get('houseNum')" />
        </div>
        <div class="info-item">
          <span v-text="'מספר דירה:'" />
          <span v-text="user.diraNum || form && form.get('diraNum')" />
        </div>
        <div class="info-item" v-if="isLogin">
          <span v-text="'תאריך יום הולדת:'" />
          <span>{{ user.day || '00' }}/{{ user.month || '00' }}/{{ user.year || '0000' }}</span>
        </div>
        <div class="info-item">
          <span v-text="'הערה:'" />
          <span v-text="form && form.get('notes')" />
        </div>
      </div>
    </div>

    <div class="cart-total">
      <div class="cart-total-body">
        <div class="cart-checkout intermediate-summary">
          <p><span class="shekel" v-text="'₪'" /> {{allPrice}}</p>
          <p>סיכום ביניים ({{allQuantity}} מוצרים)</p>
        </div>

<!--        <div class="cart-checkout intermediate-summary">-->
<!--          <p><span class="shekel" v-text="'₪'" /> 0</p>-->
<!--          <p v-text="'קופון'" />-->
<!--        </div>-->

        <div class="cart-checkout intermediate-summary">
          <p v-if="cart.delivery">
            <span class="shekel" v-text="'₪'" /> {{ cart.delivery.price }}
          </p>
          <p v-text="'שליח עד הבית'" />
        </div>

        <div class="cart-checkout intermediate-summary" v-if="cart.voucher && cart.voucher.code && cart.totalVoucher > 0">
          <p><span class="shekel" v-text="'₪'" /> -{{ cart.totalVoucher }}</p>
          <p>הנחת קופון
            (מקנה: {{ cart.voucher.amount}}{{ cart.voucher.type_discount == 'NIS' ? '₪' : "%" }} הנחה)
          </p>
        </div>

        <div class="cart-checkout total-payment">
          <p>
            <span class="shekel" v-text="'₪'" /> {{ cart.total }}
          </p>
          <p><b>סה"כ לתשלום</b></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cartMixin from '@/mixins/cart.mixin'

export default {
  name: 'PaymentInfo',

  mixins: [cartMixin],

  computed: {
    user() {
      return this.$store.getters.user
    },

    isLogin() {
      return this.$store.getters.isLogin
    },

    form() {
      return this.$store.getters.form
    },

    cart() {
      return this.$store.state.cart.cart
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.payment-container-2{
  padding-top: 5rem;
  margin-bottom: 4.4rem;
}
.payment-info{
  direction: rtl;
  text-align: right;
}
.payment-info span:last-child{
  color: #847C7C;
  margin-right: .5rem;
}

@include media-breakpoint-down(sm) {
  .payment-container-2{
    padding-top: 1rem;
    margin-bottom: 2rem;
  }
}
</style>
